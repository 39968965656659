import React, { memo } from 'react';
import Progress from 'antd/es/progress';
import loadersStyle from '../Styles/publicRoutes/loaders.module.less';
import { isBeamUser } from '../Utils/usersTypes';

export const AttentiveLoader = memo(() => {
    const { logoProgress } = loadersStyle;
    return (
        <div className='flex justify-center align-center flex-column' style={{ width: '100vw', height: '100vh' }}>
            {isBeamUser() ? (
                <img
                    alt='Beam AI logo'
                    src='https://storage.googleapis.com/falcon-shared-images-front-end/assets/beam_loader_web.webp'
                    height='140'
                    className='mb'
                />
            ) : (
                <img
                    alt='Attentive logo'
                    src='https://storage.googleapis.com/falcon-shared-images-front-end/assets/svgs/logo-only-dark.svg'
                    height='140'
                    width='140'
                    className='mb'
                />
            )}
            {!isBeamUser() && (
                <Progress
                    className={logoProgress}
                    style={{ width: '160px' }}
                    trailColor='#d3d3d378'
                    strokeColor={{
                        '0%': '#175157',
                        '100%': '#54BC7F'
                    }}
                    percent={60}
                    showInfo={false}
                    status='active'
                />
            )}
        </div>
    );
});
